import React, { useState, useEffect, useRef, useContext } from 'react';
import ComponentStyle from 'src/components/ComponentStyle';
import useLang from 'src/hooks/useLang';
import { IS_SERVER, DIMENSION_UNITS, WEATHER_WARNINGS } from 'src/constants';
import { useSelector } from 'react-redux';
import Link from 'src/front/components/Link';
import Avatar from 'src/components/Avatar';
import Helper from 'src/front/helpers/Helper';
import { FrontContext } from '../helpers/FrontContext';
import Popup from 'src/front/components/Popup';
import LoadingScreen from 'src/components/LoadingScreen';
import { useUserCan, useSimulateUser } from 'src/hooks/userHooks';
import AuthForms, { useHendleLogout } from 'src/front/components/AuthForms';
import { useSnackbar } from 'notistack';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import WeatherWarning from 'src/front/components/WeatherWarning';
import { socketON, socketOFF } from 'src/hooks/useSocket';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import { Account, Settings, Bell, Widget, Exit, BellCrossed } from 'src/front/components/icons';

import pushNotificationsService from 'src/services/pushNotificationsService';

function UserOptions() {
	const { account: { user } } = useSelector((state) => state);
	const lang = useLang('UserConfigurations');
	const element = useRef(null);

	return (
		<ComponentStyle styles={Helper.transformStyles(lang.currentLanguage, [{ src: '/css/user-options.css' }])} Preloader={null}>
			<div className="user-options" ref={element}>
        <ConfigurationsPopup element={element} lang={lang}/>
        <NotificationsPopup element={element} lang={lang}/>
        {
        	(IS_SERVER || user) &&
        	<>
        		{IS_SERVER && '{if (user) :}'}
        		<UserMenuPopup element={element} lang={lang}/>
        		{IS_SERVER && '{endif}'}
        	</>
        }
        {
        	(IS_SERVER || !user) &&
        	<>
        		{IS_SERVER && '{if (!user) :}'}
        		<AuthPopup element={element} lang={lang}/>
        		{IS_SERVER && '{endif}'}
        	</>
        }
      </div>
		</ComponentStyle>
	);
}

function ConfigurationsPopup({ lang, element }) {
	const { language: { lang: language, languages } } = useSelector((state) => state);
	const languagesFiltered = languages.filter((l) => l.active && l.ready);
	const localizationsCount = languagesFiltered.reduce((a, v) => a + v.localizations.length, 0);
	const { dimensionUnits, setProperty, staticData } = useContext(FrontContext);
	const [isOpened, setIsOpened] = useState(false);
	const [scrollButtonsActive, setScrollButtonsActive] = useState({ down: localizationsCount > 6, up: false });
	const scrollButtonsActiveRef = useRef(null);
	const styles = useRef({ button: {}, tringle: {} });
	const buttonElement = useRef(null);
	const languagesListRef = useRef(null);
	const [languagesLinks, setLanguagesLinks] = useState(null);
	const isMountedRef = useIsMountedRef();

	scrollButtonsActiveRef.current = scrollButtonsActive;

	const handleOpenPopup = () => {
		const elementBoundingRect = element.current.getBoundingClientRect();
		const buttonBoundingRect = buttonElement.current.getBoundingClientRect();

		styles.current.button = {
			right: elementBoundingRect.right - buttonBoundingRect.right,
			top: buttonBoundingRect.bottom - elementBoundingRect.bottom - buttonBoundingRect.height - 10
		};
		styles.current.tringle = { 
			'--tringle-right': `${elementBoundingRect.right - buttonBoundingRect.right + buttonBoundingRect.width - 18}px`
		};

		setIsOpened(true);
	};

	const handleClosePopup = () => setIsOpened(false);

	const handleChange = (ev) => {
		const _dimensionUnits = { ...dimensionUnits, [ev.target.getAttribute('name')]: ev.target.value };
    const date = new Date();

    date.setFullYear(date.getFullYear() + 100);

    Helper.setCookie('dimensionUnits', JSON.stringify(_dimensionUnits), { expires: date });

    setProperty('dimensionUnits', _dimensionUnits);
	};

	const scrollStep = 51;
	const handleScrollLanguagesDown = () => {
		const stepScrolled = languagesListRef.current.scrollTop % scrollStep;
		languagesListRef.current.scrollTo({ top: languagesListRef.current.scrollTop + scrollStep + (stepScrolled ? scrollStep - stepScrolled : 0), behavior: 'smooth' });
	};
	const handleScrollLanguagesUp = () => languagesListRef.current.scrollTo({ top: languagesListRef.current.scrollTop - scrollStep - (languagesListRef.current.scrollTop % scrollStep), behavior: 'smooth' });

	useEffect(() => {
		if (isOpened && languagesListRef.current) {
			setScrollButtonsActive({ down: localizationsCount > 6, up: false });

			languagesListRef.current.addEventListener('scroll', () => {
				const down = languagesListRef.current.scrollTop + languagesListRef.current.offsetHeight < languagesListRef.current.scrollHeight;
				const up = languagesListRef.current.scrollTop > 0;

				if (scrollButtonsActiveRef.current.down !== down || scrollButtonsActiveRef.current.up !== up) setScrollButtonsActive({ down, up });
			});
		}
	}, [isOpened]);

	useEffect(() => {
		staticData.setLanguagesLinks = (links) => isMountedRef.current && setLanguagesLinks(links);

		if (staticData.onInitUserOptions) {
			staticData.onInitUserOptions();
			delete staticData.onInitUserOptions;
		}

		return () => {
			if (staticData.setLanguagesLinks) delete staticData.setLanguagesLinks;
			if (staticData.onInitUserOptions) delete staticData.onInitUserOptions;
		};
	}, []);

	return (
		<>
			<button ref={buttonElement} onClick={handleOpenPopup} className="configurations">
	      <Settings className="inline-block va-middle" color={isOpened ? '#FF8A00' : '#fff'}/>
	      <span className="inline-block va-middle"></span>
	      <span className="inline-block va-middle">{IS_SERVER ? "{print DIMENSION_UNITS.find('temperature', dimensionUnits.temperature).label}" : DIMENSION_UNITS.find('temperature', dimensionUnits.temperature).label}</span>
	    </button>
	    <Popup
				anchor={element.current}
				anchorOrigin={{
	        vertical: 'bottom',
	        horizontal: 'right'
	      }}
	      popupOrigin={{
	        vertical: 'top',
	        horizontal: 'right'
	      }}
	      offset={{ top: 10, left: 0 }}
	      isOpened={isOpened}
	      onClose={handleClosePopup}
	      className="user-options-popup relative"
	      id="user-configurations-popup"
	      style={styles.current.tringle}
	    >
	    	<h3 className="header text-28 color-1">{lang.t('Site settings')}</h3>
	    	<div className="languages relative">
	    		<h4 className="text-4 color-1">{lang.t('Language')}:</h4>
	    		<ul ref={languagesListRef}>
	    			{
	    				languagesFiltered.map((l) => l.localizations.map((loc, i) => {
	    					const key = `${l._id}:${i}`;
	    					const className = [];

	    					if (l._id === lang.currentLanguage._id && i === lang.currentLocalizationIndex) className.push('current');
	    					if (languagesLinks && !(key in languagesLinks)) className.push('no-link');

	    					return (
		    					<li {...className.length && { className: className.join(' ') }}>
		    						<a href={languagesLinks && key in languagesLinks ? languagesLinks[key].link : lang.ll(languagesLinks ? lang.l('/') : window.location.href.substring(window.location.protocol.length + window.location.host.length + 2), [lang.currentLanguage._id, lang.currentLocalizationIndex], [l._id, i])}>
		    							<span className="circle"></span>
		    							{loc.flag && <img src={Helper.getFileUrl('language', loc.flag)} alt={lang.t(loc.name, null, true)} title={lang.t(loc.name, null, true)}/>}
		    							<span className="name text-3 color-1">{lang.t(loc.name)}</span>
		    						</a>
		    					</li>
		    				);
		    			}))
	    			}
	    		</ul>
	    		{
	    			localizationsCount > 6 &&
	    			<>
			    		<button onClick={handleScrollLanguagesDown} className={`scroll-down${!scrollButtonsActive.down ? ' inactive' : ''}`}></button>
			    		<button onClick={handleScrollLanguagesUp} className={`scroll-up${!scrollButtonsActive.up ? ' inactive' : ''}`}></button>
		    		</>
		    	}
	    	</div>
	    	<div className="dimension-units">
	    		<div className="dimension-units-param">
            <h4 className="text-4 color-1">
              <span>{lang.t('Temperature')}</span>:
            </h4>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="temperature" name="temperature" value={dimensionUnits?.temperature} onChange={handleChange}>
                {DIMENSION_UNITS.temperature.map((r) => (
                  <FormControlLabel value={r.value} control={<Radio />} label={lang.t(r.label)} />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
          <div className="dimension-units-param">
            <h4 className="text-4 color-1">
              <span>{lang.t('Wind speed')}</span>:
            </h4>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="windSpeed" name="windSpeed" value={dimensionUnits?.windSpeed} onChange={handleChange}>
                {DIMENSION_UNITS.windSpeed.map((r) => (
                  <FormControlLabel value={r.value} control={<Radio />} label={lang.t(r.label)} />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
          <div className="dimension-units-param">
            <h4 className="text-4 color-1">
              <span>{lang.t('Wave height')}</span>:
            </h4>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="waveHeight" name="waveHeight" value={dimensionUnits?.waveHeight} onChange={handleChange}>
                {DIMENSION_UNITS.waveHeight.map((r) => (
                  <FormControlLabel value={r.value} control={<Radio />} label={lang.t(r.label)} />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
          <div className="dimension-units-param">
            <h4 className="text-4 color-1">
              <span>{lang.t('Precipitation')}</span>:
            </h4>
            <FormControl component="fieldset">
              <RadioGroup row aria-label="precipitation" name="precipitation" value={dimensionUnits?.precipitation} onChange={handleChange}>
                {DIMENSION_UNITS.precipitation.map((r) => (
                  <FormControlLabel value={r.value} control={<Radio />} label={lang.t(r.label)} />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
	    	</div>
	    	<button onClick={handleClosePopup} className="top-icon configurations" style={styles.current.button}>
		      <Settings className="inline-block va-middle" color="#FF8A00"/>
		      <span className="inline-block va-middle"></span>
		      <span className="inline-block va-middle">{DIMENSION_UNITS.find('temperature', dimensionUnits?.temperature).label}</span>
		    </button>
	    	<div className="bottom-icon">
	    		<Settings color="#ECEFF9"/>
	    	</div>
	    </Popup>
    </>
	);
}

function NotificationsPopup({ lang, element }) {
	const { account: { user } } = useSelector((state) => state);
	const [isOpened, setIsOpened] = useState(false);
	const buttonElement = useRef(null);
	const styles = useRef({ button: {}, tringle: {} });
	const [userNotifications, setUserNotifications] = useState(null);
	const citiesRef = useRef(null);
	const weatherWarningsRef = useRef(null);
	const warningsStyle = useRef({});
	const { enqueueSnackbar } = useSnackbar();
	const [toggleInProcess, setToggleInProcess] = useState(false);
	const stateRef = useRef(null);
	const { staticData } = useContext(FrontContext);

	stateRef.current = { user };

	const handleClosePopup = () => setIsOpened(false);

	const handleOpenPopup = () => {
		if (user) {
			const elementBoundingRect = element.current.getBoundingClientRect();
			const buttonBoundingRect = buttonElement.current.getBoundingClientRect();

			styles.current.button = {
				right: elementBoundingRect.right - buttonBoundingRect.right,
				top: buttonBoundingRect.bottom - elementBoundingRect.bottom - buttonBoundingRect.height - 10
			};
			styles.current.tringle = { 
				'--tringle-right': `${elementBoundingRect.right - buttonBoundingRect.right + buttonBoundingRect.width - 14}px`
			};

			setIsOpened(true);
		} else if (staticData.handleOpenAuthPopup) staticData.handleOpenAuthPopup();
	};

	const handleShowCity = (city) => city !== userNotifications.showCity && setUserNotifications({ ...userNotifications, showCity: city });

	const handleToggleNotifications = () => {
		setToggleInProcess(true);

		pushNotificationsService.toggleNotifications()
		.then(({ data: { active } }) => {
			enqueueSnackbar(lang.t(active ? 'Notifications have been enabled' : 'Notifications have been disabled'), {
	      variant: 'success'
	    });
	    setToggleInProcess(false);
	  })
	  .catch((error) => {
	  	enqueueSnackbar(error?.response?.data || lang.t('Something wrong'), {
        variant: 'error'
      });
      setToggleInProcess(false);
	  });
	};

	const formatAndSetUserNotifications = (data) => {
		const currentSubscription = data.userSubscriptions.filter((us) => us.isCurrentClienSubscription)[0]?.weatherWarning;
		const userNotifications = { active: false, cities: {} };

		if (currentSubscription) {
			userNotifications.active = currentSubscription.active;

			for (const city in currentSubscription.subscriptions) {
				if (!userNotifications.showCity) userNotifications.showCity = city;
				userNotifications.cities[city] = { 
					name: data.subscriptionsCities[city].name,
					warnings: []
				};

				if (data.subscriptionsCities[city].weatherWarningsData) {
					for (const code in data.subscriptionsCities[city].weatherWarningsData) {
						for (const warning of WEATHER_WARNINGS) {
							if (code in warning.codes) {
								if (currentSubscription.subscriptions[city].includes(warning.name)) {
									userNotifications.cities[city].warnings.push({
										code,
										description: Helper.getFieldValue(data.warnings[code], lang.currentLanguage._id),
										dateFrom: new Date(data.subscriptionsCities[city].weatherWarningsData[code].dateFrom),
										dateUntil: new Date(data.subscriptionsCities[city].weatherWarningsData[code].dateUntil),
									});
								}
								break;
							}
						}
					}

					userNotifications.cities[city].warnings.sort((a, b) => b.dateUntil.getTime() - a.dateUntil.getTime());
				}
			}
		}

		setUserNotifications(userNotifications);
	};

	useEffect(() => {
		if (isOpened && !userNotifications) {
			pushNotificationsService.getCurrentUserWeatherWarnings()
				.then((data) => stateRef.current.user._id === user._id && formatAndSetUserNotifications(data))
				.catch(() => {});
		}
	}, [isOpened]);

	useEffect(() => {
		setUserNotifications(null);
	}, [user ? user._id : null]);

	useEffect(() => {
		if (userNotifications && citiesRef.current && weatherWarningsRef.current) {
			const citiesBoundingRect = citiesRef.current.getBoundingClientRect();
			warningsStyle.current['--cities-bottom'] = `${citiesBoundingRect.top + citiesBoundingRect.height}px`;
			weatherWarningsRef.current.style.setProperty('--cities-bottom', warningsStyle.current['--cities-bottom']);
		}
	}, [userNotifications]);

	useEffect(() => {
    pushNotificationsService.on('weatherWarningsSubscriptionsUpdate', formatAndSetUserNotifications);

    return () => pushNotificationsService.off('weatherWarningsSubscriptionsUpdate', formatAndSetUserNotifications);
	}, []);

	return (
		<>
			<button ref={buttonElement} onClick={handleOpenPopup} className="notifications relative new">
				<Bell/>
			</button>
			<Popup
				anchor={element.current}
				anchorOrigin={{
	        vertical: 'bottom',
	        horizontal: 'right'
	      }}
	      popupOrigin={{
	        vertical: 'top',
	        horizontal: 'right'
	      }}
	      offset={{ top: 10, left: 0 }}
	      isOpened={isOpened}
	      onClose={handleClosePopup}
	      className="user-options-popup relative"
	      id="user-notifications-popup"
	      style={styles.current.tringle}
	    >
	    	<div>
	    		<div className="header">
	    			<h3 className="text-28 color-1">{lang.t('Weather anomalies')}:</h3>
	    			{
	    				userNotifications &&
		    			<button onClick={handleToggleNotifications} className="toggle-notifications" disabled={toggleInProcess}>
		    				{userNotifications.active ? <BellCrossed/> : <Bell color="#BFD0E0"/>}
		    				<span className="text-12 color-1">{lang.t(userNotifications.active ? 'Turn off notifications' : 'Turn on notifications')}</span>
		    			</button>
		    		}
	    		</div>
		    	{
		    		userNotifications ?
		    		(
		    			userNotifications.showCity ?
		    			<>
		    				{
		    					Object.keys(userNotifications.cities).length > 0 &&
		    					<div ref={citiesRef} className="cities">
			    					<ul>
			    						{
			    							Object.keys(userNotifications.cities).map((city) => (
			    								<li className="relative">
			    									<button
			    										className={`text-4 ${city === userNotifications.showCity ? 'color-10' : 'color-1'}`}
			    										onClick={() => handleShowCity(city)}
			    									>{userNotifications.cities[city].name}</button>
			    								</li>
			    							))
			    						}
			    					</ul>
		    					</div>
		    				}
		    				<ul ref={weatherWarningsRef} style={{ ...warningsStyle.current }} className="weather-warnings scroll">
		    					{
		    						userNotifications.cities[userNotifications.showCity].warnings.map((warning) => <WeatherWarning lang={lang} warning={warning}/>)
		    					}
		    				</ul>
		    			</> :
		    			'Warnings not found'
		    		) :
			    	<div className="loading-screen">
			    		<LoadingScreen/>
			    	</div>
			    }
			    <div className="footer">
			    	<Link onClick={handleClosePopup} to={lang.l('/account-management/notifications')}>
			    		<Bell color="#BFD0E0"/>
			    		<span className="text-12 color-10">{lang.t('Notification center')}</span>
			    	</Link>
			    </div>
			   </div>
	    	<button onClick={handleClosePopup} style={styles.current.button} className="notifications relative new">
					<Bell color="#FF8A00"/>
				</button>
	    </Popup>
		</>
	);
}

function UserMenuPopup({ element, lang }) {
  const { account: { user } } = useSelector((state) => state);
  const [isOpened, setIsOpened] = useState(false);
  const userCan = useUserCan();
  const simulateUser = useSimulateUser();
  const usedHandleLogout = useHendleLogout();

	const handleClosePopup = () => setIsOpened(false);

	const handleOpenPopup = () => setIsOpened(true);

	const handleExitSimulation = (ev) => {
    ev.preventDefault();

    simulateUser.exitSimulation();
    handleClosePopup();
  };

  const handleLogout = (ev) => {
  	handleClosePopup();
  	usedHandleLogout(ev);
  };

  return (
		<>
			<Avatar
				onClick={handleOpenPopup}
        firstName={IS_SERVER ? '{print user.firstName}' : user.firstName}
        lastName={IS_SERVER ? '{print user.lastName}' : user.lastName}
        style={{ borderRadius: 0, borderRadius: '4px' }}
        width="40"
        height="40"
        src={IS_SERVER ? "{print user.avatar ? Helper.getFileUrl('user-avatar', user.avatar) : ''}" : (user.avatar ? Helper.getFileUrl('user-avatar', user.avatar) : '')}
      />
      <Popup
				anchor={element.current}
				anchorOrigin={{
	        vertical: 'bottom',
	        horizontal: 'right'
	      }}
	      popupOrigin={{
	        vertical: 'top',
	        horizontal: 'right'
	      }}
	      offset={{ top: 10, left: 0 }}
	      isOpened={isOpened}
	      onClose={handleClosePopup}
	      className="user-options-popup relative"
	      id="user-menu-popup"
	      style={{ '--tringle-right': '13px' }}
	    >
	    	<ul>
	        {
	          userCan('adminPanelAccess') &&
	          <li>
	            <a onClick={handleClosePopup} rel="noopener noreferrer" target="_blank" href={lang.l('/management/app/account')}>
	            	<span className="text-4 color-1">{lang.t('Admin panel')}</span>
	            </a>
	          </li>
	        }
	        {
	          userCan('editArticles') &&
	          <li>
	            <Link onClick={handleClosePopup} to={lang.l('/article/edit/new')}>
	            	<span className="text-4 color-1">{lang.t('Add article')}</span>
	            </Link>
	          </li>
	        }
	        <li>
	          <Link onClick={handleClosePopup} to={lang.l('/account-management')}>
	          	<Account className="account-icon" stroke="#BFD0E0" fill="none"/>
	          	<span className="text-4 color-1">{lang.t('Profile')}</span>
	          </Link>
	        </li>
	        <li>
	          <Link onClick={handleClosePopup} to={lang.l('/account-management/widgets')}>
	          	<Widget/>
	          	<span className="text-4 color-1">{lang.t('Widgets')}</span>
	          </Link>
	        </li>
	        {
	          userCan('adminPanelAccess') &&
	          <li>
	            {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
	            <a href="#">
	            	<Settings color="#BFD0E0" className="settings-icon"/>
	            	<span className="text-4 color-1">{lang.t('Settings')}</span>
	            </a>
	          </li>
	        }
	        {
	          user?.simulated &&
	          <li>
	            <Link onClick={handleExitSimulation} to={'#'}>
	            	<span className="text-4 color-1">{lang.t('Exit simulation')}</span>
	            </Link>
	          </li>
	        }
	        <li>
	          {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
	          <a href="#" onClick={handleLogout}>
	          	<Exit/>
	          	<span className="text-4 color-12">{lang.t('Exit')}</span>
	          </a>
	        </li>
	      </ul>
	      <Avatar
					onClick={handleClosePopup}
	        firstName={user?.firstName}
	        lastName={user?.lastName}
	        style={{ borderRadius: 0, borderRadius: '4px' }}
	        width="40"
	        height="40"
	        src={user?.avatar ? Helper.getFileUrl('user-avatar', user.avatar) : ''}
	      />
	    </Popup>
		</>
  );
}

function AuthPopup({ element, lang }) {
	const [isOpened, setIsOpened] = useState(false);
	const buttonElement = useRef(null);
	const styles = useRef({ tringle: {} });
	const { staticData } = useContext(FrontContext);

	const handleClosePopup = () => setIsOpened(false);

	useEffect(() => {
  	staticData.handleOpenAuthPopup = () => {
			const elementBoundingRect = element.current.getBoundingClientRect();
			const buttonBoundingRect = buttonElement.current.getBoundingClientRect();

			styles.current.tringle = { 
				'--tringle-right': `${elementBoundingRect.right - buttonBoundingRect.right + buttonBoundingRect.width - 22}px`
			};

			setIsOpened(true);
		};

  	return () => delete staticData.handleOpenAuthPopup;
  }, []);

	return (
		<>
			<button onClick={staticData.handleOpenAuthPopup || (() => {})} ref={buttonElement} className="auth">
				<Account stroke="#ff9c00" fill="none"/>
	      <span className="text-10 color-18">{lang.t('Log in to your account')}</span>
      </button>
      <Popup
				anchor={element.current}
				anchorOrigin={{
	        vertical: 'bottom',
	        horizontal: 'right'
	      }}
	      popupOrigin={{
	        vertical: 'top',
	        horizontal: 'right'
	      }}
	      offset={{ top: 10, left: 0 }}
	      isOpened={isOpened}
	      onClose={handleClosePopup}
	      className="user-options-popup relative"
	      id="user-auth-popup"
	      style={styles.current.tringle}
	    >
	    	<AuthForms onBeforeAuth={handleClosePopup} place="popup"/>
	    	<button onClick={handleClosePopup} className="auth">
					<Account stroke="#ff9c00" fill="none"/>
		      <span className="text-10 color-18">{lang.t('Log in to your account')}</span>
	      </button>
	    </Popup>
		</>
	);
}

export default UserOptions;