import loadable from '@loadable/component';
import Main from 'src/front/layouts/Main';

const Home = loadable(() => import('src/front/views/Home'));
const CountriesList = loadable(() => import('src/front/views/CountriesList'));
const Advertising = loadable(() => import('src/front/views/Advertising'));
const Widgets = loadable(() => import('src/front/views/Widgets1'));
const Beaches = loadable(() => import('src/front/views/Beaches'));
const Beach = loadable(() => import('src/front/views/Beach'));
const Countries = loadable(() => import('src/front/views/Countries'));
const Country = loadable(() => import('src/front/views/Country'));
const RegionCity = loadable(() => import('src/front/views/RegionCity'));
const News = loadable(() => import('src/front/views/News'));
const NewsInner = loadable(() => import('src/front/views/NewsInner'));
const Partnership = loadable(() => import('src/front/views/Partnership'));
const ServiceInformation = loadable(() => import('src/front/views/ServiceInformation'));
const PrivacyPolicy = loadable(() => import('src/front/views/PrivacyPolicy'));
const UserDeletion = loadable(() => import('src/front/views/UserDeletion'));
const Feedback = loadable(() => import('src/front/views/Feedback'));
const AccountManagement = loadable(() => import('src/front/views/AccountManagement'));
const AccountManagementWidgets = loadable(() => import('src/front/views/AccountManagement/AccountManagementWidgets'));
const AccountManagementNotifications = loadable(() => import('src/front/views/AccountManagement/AccountManagementNotifications'));
const AccountManagementPasswordRecovery = loadable(() => import('src/front/views/AccountManagement/AccountManagementPasswordRecovery'));
const Rss = loadable(() => import('src/front/views/Rss'));
const Articles = loadable(() => import('src/front/views/Articles'));
const Article = loadable(() => import('src/front/views/article/Article'));
const EditArticle = loadable(() => import('src/front/views/article/Edit'));
const Page404 = loadable(() => import('src/front/views/Page404'));

export default [
  {
    name: 'home',
    exact: true,
    path: '/',
    component: Home,
    bodyClass: 'home',
    ogImageDir: 'page',
    fallback: {
      name: 'home',
      props: ['lang']
    },
    load: {
      translates: { url: `/lang/get-translates/Front,Top,Footer,RightSidebar,LeftSidebar,Home,PageInfoButtons,SearchLocation,InfoBlocks,Rss,SunPosition,CitiesList/:currentLanguage`, cache: 'translates' },
      page: { url: '/home-page/:currentLanguage/:currentLocalization', cache: 'url' },
      news: { url: '/sidebar-news?country=:country', cache: 'news' },
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
      infoBlocks: true,
    },
    microdataName: 'WebSite'
  },
  {
    name: 'map',
    exact: true,
    path: '/:region(europe|america|cis|asia|africa|australia_newzealand)/:mapParams?/:mapParams2?',
    component: CountriesList,
    bodyClass: 'page-countries-list',
    ogImageDir: 'page',
    fallback: {
      name: 'countriesList',
      props: [['bodyClass', 'page-countries-list']]
    },
    load: {
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
      translates: { url: `/lang/get-translates/Front,Top,Footer,RightSidebar,CountriesList,SearchLocation/:currentLanguage`, cache: 'translates' },
      news: { url: '/sidebar-news?country=:country', cache: 'news' },
      page: { url: '/:region', cache: 'url' },
    },
    microdataName: 'Map'
  },
  {
    name: 'countries',
    exact: true,
    path: '/country',
    component: Countries,
    bodyClass: 'countries',
    ogImageDir: 'page',
    fallback: {
      name: 'base',
      props: [['bodyClass', 'countries']]
    },
    load: {
      translates: { url: `/lang/get-translates/Front,Top,Footer,RightSidebar,LeftSidebar,Countries,PageInfoButtons,SearchLocation,InfoBlocks,Rss/:currentLanguage`, cache: 'translates' },
      page: { url: '/countries', cache: 'url' },
      news: { url: `/sidebar-news?country=:country`, cache: 'news' },
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
      infoBlocks: true,
    },
    microdataName: 'BreadcrumbList'
  },
  {
    name: 'account management widgets',
    exact: true,
    path: '/account-management/widgets',
    component: AccountManagementWidgets,
    bodyClass: 'acount-management acount-widgets',
    ogImageDir: 'page',
    fallback: {
      name: 'accountManagement',
      props: [['bodyClass', 'acount-management acount-widgets']]
    },
    load: {
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
      translates: { url: `/lang/get-translates/Front,Top,Footer,RightSidebar,AuthForms,AccountManagementWidgets,AccountManagementLayout/:currentLanguage`, cache: 'translates' },
      page: { url: '/acount-management-widgets', cache: 'url' }
    }
  },
  {
    name: 'account management notifications',
    exact: true,
    path: '/account-management/notifications',
    component: AccountManagementNotifications,
    bodyClass: 'acount-management acount-notifications',
    ogImageDir: 'page',
    fallback: {
      name: 'accountManagement',
      props: [['bodyClass', 'acount-management acount-notifications']]
    },
    load: {
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
      translates: { url: `/lang/get-translates/Front,Top,Footer,RightSidebar,AuthForms,AccountManagementNotifications,AccountManagementLayout/:currentLanguage`, cache: 'translates' },
      page: { url: '/acount-management-notifications', cache: 'url' }
    }
  },
  {
    name: 'account management password recovery',
    exact: true,
    path: '/account-management/password-recovery',
    component: AccountManagementPasswordRecovery,
    bodyClass: 'acount-management password-recovery',
    ogImageDir: 'page',
    fallback: {
      name: 'accountManagement',
      props: [['bodyClass', 'acount-management password-recovery']]
    },
    load: {
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
      translates: { url: `/lang/get-translates/Front,Top,Footer,RightSidebar,AuthForms,AccountManagementPasswordRecovery,AccountManagementLayout/:currentLanguage`, cache: 'translates' },
      page: { url: '/acount-management-password-recovery', cache: 'url' }
    }
  },
  {
    name: 'account management',
    exact: true,
    path: '/account-management',
    component: AccountManagement,
    bodyClass: 'acount-management',
    ogImageDir: 'page',
    fallback: {
      name: 'accountManagement',
      props: [['bodyClass', 'acount-management']]
    },
    load: {
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
      translates: { url: `/lang/get-translates/Front,Top,Footer,RightSidebar,AuthForms,AccountManagement,AccountManagementLayout/:currentLanguage`, cache: 'translates' },
      page: { url: '/acount-management', cache: 'url' }
    }
  },
  {
    name: 'privacy policy',
    layout: Main,
    exact: true,
    path: '/privacy-policy',
    component: PrivacyPolicy,
    bodyClass: 'privacy-policy',
    ogImageDir: 'page',
    fallback: {
      name: 'loading',
      props: [['bodyClass', 'privacy-policy']]
    },
    load: {
      translates: { url: `/lang/get-translates/Front,Top,Footer,RightSidebar,LeftSidebar,PrivacyPolicy,PageInfoButtons,SearchLocation,Rss/:currentLanguage`, cache: 'translates' },
      page: { url: '/privacy-policy', cache: 'url' },
      news: { url: `/sidebar-news?country=:country`, cache: 'news' },
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
    },
    microdataName: 'BreadcrumbList'
  },
  {
    name: 'user deletion',
    layout: Main,
    exact: true,
    path: '/user-deletion',
    component: UserDeletion,
    bodyClass: 'user-deletion',
    ogImageDir: 'page',
    fallback: {
      name: 'loading',
      props: [['bodyClass', 'user-deletion']]
    },
    load: {
      translates: { url: `/lang/get-translates/Front,Top,Footer,RightSidebar,LeftSidebar,UserDeletion,PageInfoButtons,SearchLocation,Rss/:currentLanguage`, cache: 'translates' },
      page: { url: '/user-deletion', cache: 'url' },
      news: { url: `/sidebar-news?country=:country`, cache: 'news' },
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
    },
    microdataName: 'BreadcrumbList'
  },
  {
    name: 'advertising',
    exact: true,
    path: '/advertising/',
    component: Advertising,
    bodyClass: 'advertising',
    ogImageDir: 'page',
    fallback: {
      name: 'base',
      props: [['bodyClass', 'advertising']]
    },
    load: {
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
      translates: { url: `/lang/get-translates/Front,Top,Footer,RightSidebar,Advertising,PageInfoButtons,SearchLocation/:currentLanguage`, cache: 'translates' },
      page: { url: '/advertising', cache: 'url' },
    },
    microdataName: 'BreadcrumbList'
  },
  {
    name: 'widgets',
    exact: true,
    path: '/widgets/',
    component: Widgets,
    bodyClass: 'widgets',
    ogImageDir: 'page',
    fallback: {
      name: 'base',
      props: [['bodyClass', 'widgets']]
    },
    load: {
      translates: { url: `/lang/get-translates/Front,Top,Footer,RightSidebar,LeftSidebar,Widgets1,WidgetCityConfig,WidgetCitiesConfig,WidgetToolbarConfig,PageInfoButtons,SearchLocation/:currentLanguage`, cache: 'translates' },
      page: { url: '/widgets', cache: 'url' },
      news: { url: `/sidebar-news?country=:country`, cache: 'news' },
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
    },
    microdataName: 'BreadcrumbList'
  },
  {
    name: 'partnership',
    layout: Main,
    exact: true,
    path: '/partnership/',
    component: Partnership,
    bodyClass: 'partnership',
    ogImageDir: 'page',
    fallback: {
      name: 'loading',
      props: [['bodyClass', 'partnership']]
    },
    load: {
      news: { url: '/sidebar-news?country=:country', cache: 'news' },
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
      translates: { url: `/lang/get-translates/Front,Top,Footer,Partnership,RightSidebar,LeftSidebar,PageInfoButtons,SearchLocation/:currentLanguage`, cache: 'translates' },
      page: { url: '/partnership', cache: 'url' },
    },
    microdataName: 'BreadcrumbList'
  },
  {
    name: 'service information',
    layout: Main,
    exact: true,
    path: '/service_information/',
    component: ServiceInformation,
    bodyClass: 'service-information',
    ogImageDir: 'page',
    fallback: {
      name: 'loading',
      props: [['bodyClass', 'service-information']]
    },
    load: {
      news: { url: '/sidebar-news?country=:country', cache: 'news' },
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
      translates: { url: `/lang/get-translates/Front,Top,Footer,ServiceInformation,RightSidebar,LeftSidebar,PageInfoButtons,SearchLocation/:currentLanguage`, cache: 'translates' },
      page: { url: '/service-information', cache: 'url' },
    },
    microdataName: 'BreadcrumbList'
  },
  {
    name: 'feedback',
    layout: Main,
    exact: true,
    path: '/feedback/',
    component: Feedback,
    bodyClass: 'feedback',
    ogImageDir: 'page',
    fallback: {
      name: 'loading',
      props: [['bodyClass', 'feedback']]
    },
    load: {
      news: { url: '/sidebar-news?country=:country', cache: 'news' },
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
      translates: { url: `/lang/get-translates/Front,Top,Footer,Feedback,RightSidebar,LeftSidebar,PageInfoButtons,SearchLocation,Rss/:currentLanguage`, cache: 'translates' },
      page: { url: '/feedback', cache: 'url' },
    },
    microdataName: 'ContactPage'
  },
  {
    name: 'beach',
    exact: true,
    path: '/beaches/:beach',
    component: Beach,
    bodyClass: 'beach-inner',
    ogImageDir: 'beach',
    fallback: {
      name: 'base',
      props: [['bodyClass', 'beach-inner']]
    },
    load: {
      translates: { url: `/lang/get-translates/Front,Top,Footer,RightSidebar,LeftSidebar,Beach,TempGraph,PageInfoButtons,SearchLocation,InfoBlocks,Rss,CitiesList/:currentLanguage`, cache: 'translates' },
      page: { url: '/beaches/:beach', cache: 'url' },
      news: { url: `/sidebar-news?country=:country`, cache: 'news' },
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
      infoBlocks: true,
    },
  },
  {
    name: 'beaches',
    exact: true,
    path: '/beaches/',
    component: Beaches,
    bodyClass: 'page-beaches',
    ogImageDir: 'page',
    fallback: {
      name: 'base',
      props: [['bodyClass', 'page-beaches']]
    },
    load: {
      translates: { url: `/lang/get-translates/Front,Top,Footer,RightSidebar,LeftSidebar,Beaches,TempGraph,PageInfoButtons,SearchLocation,InfoBlocks,Rss/:currentLanguage`, cache: 'translates' },
      page: { url: '/beaches/', cache: 'url' },
      news: { url: `/sidebar-news?country=:country`, cache: 'news' },
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
      infoBlocks: true,
    },
    microdataName: 'BreadcrumbList'
  },
  {
    name: 'news inner',
    layout: Main,
    exact: true,
    path: '/news/weather-in-:country-:date(\\w+)',
    component: NewsInner,
    bodyClass: 'news-inner',
    ogImageDir: 'news',
    fallback: {
      name: 'loading',
      props: [['bodyClass', 'news-inner']]
    },
    load: {
      translates: { url: `/lang/get-translates/Front,Top,Footer,RightSidebar,LeftSidebar,NewsInner,TempGraph,PageInfoButtons,SearchLocation,Rss/:currentLanguage`, cache: 'translates' },
      page: { url: '/news-inner/:country/:date', cache: 'news_inner' },
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
    },
    microdataName: 'Article'
  },
  {
    name: 'news list',
    layout: Main,
    exact: true,
    path: '/news/:country?',
    component: News,
    bodyClass: 'news-list',
    ogImageDir: 'page',
    fallback: {
      name: 'loading',
      props: [['bodyClass', 'news-list']]
    },
    load: {
      translates: { url: `/lang/get-translates/Front,Top,Footer,RightSidebar,LeftSidebar,News,PageInfoButtons,SearchLocation,InfoBlocks,Rss/:currentLanguage`, cache: 'translates' },
      page: { url: '/news?country=:country', cache: 'news' },
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
      infoBlocks: true,
    },
  },
  {
    name: 'rss',
    layout: Main,
    exact: true,
    path: '/rss/',
    component: Rss,
    bodyClass: 'rss',
    ogImageDir: 'page',
    fallback: {
      name: 'loading',
      props: [['bodyClass', 'rss']]
    },
    load: {
      translates: { url: `/lang/get-translates/Front,Top,Footer,RightSidebar,LeftSidebar,PageInfoButtons,InfoBlocks,Rss,SearchLocation/:currentLanguage`, cache: 'translates' },
      page: { url: '/front/rss', cache: 'url' },
      news: { url: `/sidebar-news?country=:country`, cache: 'news' },
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
      infoBlocks: true,
    },
    microdataName: 'BreadcrumbList'
  },
  {
    name: 'articles',
    exact: true,
    path: ['/articles', '/articles/tag/:tag'],
    component: Articles,
    bodyClass: 'articles',
    ogImageDir: 'article',
    fallback: {
      name: 'base',
      props: [['bodyClass', 'articles']]
    },
    load: {
      translates: { url: `/lang/get-translates/Front,Top,RightSidebar,LeftSidebar,Articles,SearchLocation/:currentLanguage`, cache: 'translates' },
      page: { type: 'ServerActions', action: 'articles', params: ['currentLanguage', 'replace'] },
      news: { url: `/sidebar-news?country=:country`, cache: 'news' },
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
      infoBlocks: true,
    }
  },
  {
    name: 'article',
    exact: true,
    path: '/article/:url',
    component: Article,
    bodyClass: 'article',
    ogImageDir: 'article',
    fallback: {
      name: 'loading',
      props: [['bodyClass', 'article']]
    },
    load: {
      translates: { url: `/lang/get-translates/Front,Footer,Article/:currentLanguage`, cache: 'translates' },
      page: { type: 'ServerActions', action: 'article', params: ['replace', 'currentLanguage', 'user'] },
    }
  },
  {
    name: 'article edit',
    exact: true,
    path: '/article/edit/:id',
    component: EditArticle,
    bodyClass: 'article-edit',
    ogImageDir: 'page',
    fallback: {
      name: 'loading',
      props: [['bodyClass', 'article-edit']]
    },
    load: {
      translates: { url: `/lang/get-translates/Front,EditArticle/:currentLanguage`, cache: 'translates' },
      page: { type: 'ServerActions', action: 'articleEdit', params: ['url', 'currentLanguage', 'languages', 'request'] },
    }
  },
  {
    name: 'country',
    exact: true,
    path: '/:country',
    component: Country,
    bodyClass: 'country-page',
    ogImageDir: 'country',
    fallback: {
      name: 'base',
      props: [['bodyClass', 'country-page']]
    },
    load: {
      translates: { url: `/lang/get-translates/Front,Top,Footer,RightSidebar,LeftSidebar,Country,PageInfoButtons,SearchLocation,InfoBlocks,Rss,CitiesList/:currentLanguage`, cache: 'translates' },
      // page: { url: '/country-regions/:country', cache: 'url' },
      page: { type: 'ServerActions', action: 'country', params: ['url', 'languages', 'currentLanguage', 'localization'] },
      news: { url: `/sidebar-news?country=:country`, cache: 'news' },
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
      infoBlocks: true,
    },
  },
  {
    name: 'region city',
    exact: true,
    path: '/:country/:regionCity',
    component: RegionCity,
    fallback: {
      name: 'base',
      props: [['bodyClass', '']]
    },
    load: {
      translates: { url: `/lang/get-translates/Front,Top,Footer,RightSidebar,LeftSidebar,RegionCity,Region,City,TempGraph,PageInfoButtons,SearchLocation,InfoBlocks,Rss,CitiesList/:currentLanguage`, cache: 'translates' },
      // page: { url: '/region-city/:country/:regionCity', cache: 'url' },
      page: { type: 'ServerActions', action: 'regionCity', params: ['url', 'languages', 'currentLanguage', 'localization'] },
      news: { url: `/sidebar-news?country=:country`, cache: 'news' },
      sidebarLocations: { url: '/sidebar-cities?country=:country', cache: 'url' },
      infoBlocks: true,
    },
  },
  {
    name: '404',
    bodyClass: 'page-not-found',
    exact: true,
    component: Page404,
    fallback: {
      name: 'base',
      props: [['bodyClass', '']]
    },
    load: {
      translates: { url: `/lang/get-translates/404/:currentLanguage`, cache: 'translates' },
      page: { url: '/front/page-404', cache: 'url' },
    },
  },
];