import React, { useEffect, useRef, useState } from 'react';
import ResizeSensible from '../ResizeSensible';
import $ from 'jquery';
import { useSelector } from 'react-redux';
import Helper from 'src/front/helpers/Helper';

import './style.css';

export default ({
  children, tag: WrapperTag, props = {}, enableScrollNoScroll = true, enableScrollEndScroll = true, arrowsSelector, resizeProps,
  scrollbar = false, scrollWheel = true
}) => {
  const { lang, languages } = useSelector(({ language }) => language);
  const language = languages.filter((l) => l._id === lang)[0];
  const element = useRef(null);
  const [arrowsVisibility, setArrowsVisibility] = useState({
    left: { render: false, transparent: true, timer: null },
    right: { render: false, transparent: true, timer: null }
  });
  const [scrollbarThumbStyle, setScrollbarThumbStyle] = useState({ display: 'none' })
  const arrowFlag = useRef(true);
  const arrowsVisibilityRef = useRef(null);
  const scrollFault = useRef(null);
  const margin = 10;
  const margin2 = 20;

  arrowsVisibilityRef.current = arrowsVisibility;

  const getElementOffset = (el) => {
    let offsetLeft = el.offsetLeft;
    let offsetParent = el.offsetParent;

    const addScrolledOffset = (parentNode) => {
      if (offsetParent) {
        while (parentNode !== offsetParent) {
          parentNode = parentNode.parentNode;
          if (!parentNode) break;
          offsetLeft += parentNode.scrollWidth - parentNode.offsetWidth;
        }
      }
    };

    if (offsetParent && language.direction !== 'ltr') addScrolledOffset(el);

    while (offsetParent && element.current !== offsetParent) {
      const _el = offsetParent;

      offsetLeft += offsetParent.offsetLeft;
      offsetParent = offsetParent.offsetParent;

      if (offsetParent && language.direction !== 'ltr') addScrolledOffset(_el);
    }

    return offsetLeft;
  };

  const handleCheckArrows = () => {
    if (element.current && (arrowsSelector || scrollbar)) {
      if (scrollFault.current === null && element.current.offsetParent) {
        element.current.scrollLeft = language.direction === 'ltr' ? -10 : 10;
        scrollFault.current = element.current.scrollLeft;
      }

      let needRender = false;
      const isScrolledLeft = language.direction === 'ltr' ? element.current.scrollLeft > 0 : Math.abs(element.current.scrollLeft - scrollFault.current) + element.current.clientWidth < element.current.scrollWidth;
  
      if (isScrolledLeft && (!arrowsVisibilityRef.current.left.render || arrowsVisibilityRef.current.left.transparent)) {
        needRender = true;
  
        if (arrowsVisibilityRef.current.left.timer) {
          arrowsVisibilityRef.current.left.transparent = false;
          clearTimeout(arrowsVisibilityRef.current.left.timer);
          arrowsVisibilityRef.current.left.timer = null;
        } else {
          arrowsVisibilityRef.current.left.render = true;
        }
      } else if (!isScrolledLeft && ((arrowsVisibilityRef.current.left.render && !arrowsVisibilityRef.current.left.timer) || !arrowsVisibilityRef.current.left.transparent)) {
        needRender = true;
  
        arrowsVisibilityRef.current.left.transparent = true;
        arrowsVisibilityRef.current.left.timer = setTimeout(() => {
          arrowsVisibilityRef.current.left.render = false;
          arrowsVisibilityRef.current.left.timer = null;
          setArrowsVisibility({ ...arrowsVisibilityRef.current });
        }, 300);
      }
  
      const isScrolledRight = language.direction === 'ltr' ? element.current.scrollLeft - scrollFault.current + element.current.clientWidth < element.current.scrollWidth : element.current.scrollLeft < 0;
  
      if (isScrolledRight && (!arrowsVisibilityRef.current.right.render || arrowsVisibilityRef.current.right.transparent)) {
        needRender = true;
  
        if (arrowsVisibilityRef.current.right.timer) {
          arrowsVisibilityRef.current.right.transparent = false;
          clearTimeout(arrowsVisibilityRef.current.right.timer);
          arrowsVisibilityRef.current.right.timer = null;
        } else {
          arrowsVisibilityRef.current.right.render = true;
        }
      } else if (!isScrolledRight && ((arrowsVisibilityRef.current.right.render && !arrowsVisibilityRef.current.right.timer) || !arrowsVisibilityRef.current.right.transparent)) {
        needRender = true;
  
        arrowsVisibilityRef.current.right.transparent = true;
        arrowsVisibilityRef.current.right.timer = setTimeout(() => {
          arrowsVisibilityRef.current.right.render = false;
          arrowsVisibilityRef.current.right.timer = null;
          setArrowsVisibility({ ...arrowsVisibilityRef.current });
        }, 300);
      }
  
      if (needRender) setArrowsVisibility({ ...arrowsVisibilityRef.current });

      if (scrollbar) {
        const scrolled = element.current.scrollLeft;
        const maxScroll = element.current.scrollWidth - element.current.clientWidth;
        const thumb = element.current.parentNode.getElementsByClassName('scroll-hidden-scrollbar-thumb')[0];
        const pathWidth = element.current.parentNode.getElementsByClassName('scroll-hidden-scrollbar-path')[0].offsetWidth;
        const thumbWidth = thumb.offsetWidth;
        const newStyle = {};

        if (maxScroll > 0) newStyle.left = `${scrolled / maxScroll * (pathWidth - thumbWidth)}px`;
        else newStyle.display = 'none';

        if (!Helper.compare(newStyle, scrollbarThumbStyle)) setScrollbarThumbStyle(newStyle);
      }
    }
  };

  const handleClickLeft = (ev) => {
    ev.preventDefault();

    if (arrowFlag.current) {
      arrowFlag.current = false;

      const elements = [];
      const scrolledLeft = language.direction === 'ltr' ? element.current.scrollLeft - scrollFault.current : element.current.scrollLeft - scrollFault.current + element.current.scrollWidth - element.current.offsetWidth;
      let scrollLeft = language.direction === 'ltr' ? 0 : element.current.offsetWidth - element.current.scrollWidth - scrollFault.current;

      $(element.current).find(arrowsSelector).each(function () { if (this.offsetParent) elements.push(this); });

      if (language.direction !== 'ltr') elements.reverse();

      for (let i = elements.length; i--;) {
        if (i) {
          const elementOffset = getElementOffset(elements[i]) + margin;

          if (elementOffset < scrolledLeft) {
            scrollLeft = Math.max(elementOffset - margin - margin2, getElementOffset(elements[i - 1]) + elements[i - 1].offsetWidth);
            if (language.direction !== 'ltr') scrollLeft -= element.current.scrollWidth - element.current.offsetWidth;
            break;
          }
        }
      }

      $(element.current).animate({ scrollLeft }, 300, () => arrowFlag.current = true);
    }
  };

  const handleClickRight = (ev) => {
    ev.preventDefault();

    if (arrowFlag.current) {
      arrowFlag.current = false;

      const elements = [];
      const scrolledLeft = language.direction === 'ltr' ? element.current.scrollLeft + element.current.clientWidth : element.current.scrollWidth + element.current.scrollLeft;
      let scrollLeft = language.direction === 'ltr' ? element.current.scrollWidth - element.current.clientWidth + 10 : 0;

      $(element.current).find(arrowsSelector).each(function () { if (this.offsetParent) elements.push(this); });

      if (language.direction !== 'ltr') elements.reverse();

      for (let i = 0; i < elements.length; i++) {
        if (i + 1 < elements.length) {
          const elementOffset = getElementOffset(elements[i]) + elements[i].offsetWidth - margin;

          if (elementOffset > scrolledLeft) {
            scrollLeft = Math.min(elementOffset + margin + margin2, getElementOffset(elements[i + 1])) - element.current.clientWidth;
            if (language.direction !== 'ltr') scrollLeft -= element.current.scrollWidth - element.current.offsetWidth;
            break;
          }
        }
      }

      $(element.current).animate({ scrollLeft }, 300, () => arrowFlag.current = true);
    }
  };

  useEffect(() => {
    if (arrowsVisibilityRef.current.left.render) {
      arrowsVisibilityRef.current.left.transparent = false;
      setArrowsVisibility({ ...arrowsVisibilityRef.current });
    }
  }, [arrowsVisibility.left.render]);

  useEffect(() => {
    if (arrowsVisibilityRef.current.right.render) {
      arrowsVisibilityRef.current.right.transparent = false;
      setArrowsVisibility({ ...arrowsVisibilityRef.current });
    }
  }, [arrowsVisibility.right.render]);

  useEffect(() => {
    if (element.current) {
      const handleWheel = (ev) => {
        const delta = Math.max(-1, Math.min(1, (ev.wheelDelta || -ev.detail)));

        if ((!enableScrollNoScroll || element.current.scrollWidth > element.current.clientWidth) &&
            (!enableScrollEndScroll || element.current.scrollWidth <= element.current.clientWidth || (delta > 0 && element.current.scrollLeft) || (delta < 0 && Math.abs(element.current.scrollWidth - element.current.clientWidth - element.current.scrollLeft) > 1))) {
          ev.preventDefault();
        }

        if (language.direction === 'ltr') element.current.scrollLeft -= delta * 10;
        else element.current.scrollLeft += delta * 10;
      };

      let startX = null;
      let wasMove = false;
      const mouseDown = (ev) => {
        ev.preventDefault();

        startX = ev.type === 'touchstart' ? ev.touches[0].pageX : ev.pageX;

        document.addEventListener('mousemove', mouseMove, { passive: false });
        document.addEventListener('touchmove', mouseMove, { passive: false });
        document.addEventListener('mouseup', mouseUp, { passive: false });
        document.addEventListener('touchend', mouseUp, { passive: false });
      };
      const mouseMove = (ev) => {
        /*if (ev.type !== 'touchmove') */ev.preventDefault();

        const x = ev.type === 'touchmove' ? ev.touches[0].pageX : ev.pageX;

        element.current.scrollLeft += startX - x;

        startX = x;
        wasMove = true;
      };
      const mouseUp = (ev) => {
        setTimeout(() => wasMove = false);

        document.removeEventListener('mousemove', mouseMove, { passive: false });
        document.removeEventListener('touchmove', mouseMove, { passive: false });
        document.removeEventListener('mouseup', mouseUp);
        document.removeEventListener('touchend', mouseUp);
      };

      if (scrollWheel) {
        element.current.addEventListener('mousewheel', handleWheel, { passive: false });
        element.current.addEventListener('DOMMouseScroll', handleWheel, { passive: false });
      }

      element.current.addEventListener('mousedown', mouseDown);
      element.current.addEventListener('touchstart', mouseDown);
      element.current.addEventListener('click', (ev) => {
        if (wasMove) {
          ev.preventDefault();
          ev.stopPropagation();
          wasMove = false;
        }
      }, { passive: false });

      if (scrollbar) {
        const thumb = element.current.parentNode.getElementsByClassName('scroll-hidden-scrollbar-thumb')[0];
        const path = element.current.parentNode.getElementsByClassName('scroll-hidden-scrollbar-path')[0];
        let maxScroll = null;
        let thumbMoveWidth = null;
        let scrolledLeft = null;
        let thumbStartX = null;

        const mouseDownThumb = (ev) => {
          ev.preventDefault();

          thumbStartX = ev.type === 'touchstart' ? ev.touches[0].pageX : ev.pageX;
          maxScroll = element.current.scrollWidth - element.current.clientWidth;
          thumbMoveWidth = path.clientWidth - thumb.offsetWidth;
          scrolledLeft = element.current.scrollLeft;

          document.addEventListener('mousemove', mouseMoveThumb, { passive: false });
          document.addEventListener('touchmove', mouseMoveThumb, { passive: false });
          document.addEventListener('mouseup', mouseUpThumb, { passive: false });
          document.addEventListener('touchend', mouseUpThumb, { passive: false });
        };
        const mouseMoveThumb = (ev) => {
          /*if (ev.type !== 'touchmove') */ev.preventDefault();

          const x = ev.type === 'touchmove' ? ev.touches[0].pageX : ev.pageX;

          element.current.scrollLeft = (x - thumbStartX) / thumbMoveWidth * maxScroll + scrolledLeft;
        };
        const mouseUpThumb = (ev) => {
          document.removeEventListener('mousemove', mouseMoveThumb, { passive: false });
          document.removeEventListener('touchmove', mouseMoveThumb, { passive: false });
          document.removeEventListener('mouseup', mouseUpThumb);
          document.removeEventListener('touchend', mouseUpThumb);
        };

        thumb.addEventListener('mousedown', mouseDownThumb);
        thumb.addEventListener('touchstart', mouseDownThumb);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(handleCheckArrows);

  if (element.current && element.current.scrollWidth - element.current.clientWidth) {
    props = props ? { ...props } : {};
    props.className = props.className ? `${props.className} with-scroll` : 'with-scroll';
  }

  return (
    arrowsSelector || scrollbar ?
    <ResizeSensible callback={handleCheckArrows} onScroll={handleCheckArrows} {...resizeProps || {}}>
      <WrapperTag {...props} ref={element}>{children}</WrapperTag>
      {
        arrowsSelector && arrowsVisibility.left.render &&
        <>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a aria-label="Left" href="#" onClick={handleClickLeft} className={`scroll-hidden-arrow scroll-hidden-left${!arrowsVisibility.left.transparent ? ' visible' : ''}`}>
            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>
            </svg>
          </a>
        </>
      }
      {
        arrowsSelector && arrowsVisibility.right.render &&
        <>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a aria-label="Right" href="#" onClick={handleClickRight} className={`scroll-hidden-arrow scroll-hidden-right${!arrowsVisibility.right.transparent ? ' visible' : ''}`}>
            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>
            </svg>
          </a>
        </>
      }
      {
        scrollbar &&
        <div className="scroll-hidden-scrollbar-path" {...scrollbarThumbStyle.display === 'none' && { style: { display: 'none' } }}>
          <div className="scroll-hidden-scrollbar-thumb" style={scrollbarThumbStyle}>
            <div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      }
    </ResizeSensible> :
    <WrapperTag {...props} ref={element}>{children}</WrapperTag>
  );
};